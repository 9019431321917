import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-select-wa-sms',
	templateUrl: './dialog-select-wa-sms.component.html',
	styleUrls: ['./dialog-select-wa-sms.component.scss'],
})
export class DialogSelectWaSMSComponent {
	constructor(public dialogRef: MatDialogRef<DialogSelectWaSMSComponent>) {}

	sms() {
		this.dialogRef.close('sms');
	}

	wa() {
		this.dialogRef.close('wa');
	}
}
