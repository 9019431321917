import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	AddReleaseNoteDocument,
	CreateIcsIdDocument,
	CreateIcsIdUserDocument,
	DeleteReleaseNoteDocument,
	GetAllReleaseNotesDocument,
	GetDefaultLocationDocument,
	GetFlightDocument,
	GetLocationsDocument,
	GetReleaseNoteDocument,
	GetReleaseNotesCountDocument,
	GetReleaseNotesDocument,
	GetSubscriptionDocument,
	GetTenantSettingDocument,
	GetTenantSettingMyFlightDocument,
	GetUpUrlDocument,
	GetUserDocument,
	InputLocationOptions,
	InputOperatorSettingsBackup,
	InputOperatorSettingsCrew,
	InputOperatorSettingsFlights,
	InputOperatorSettingsMyFlight,
	InputOperatorSettingsNotifications,
	InputOperatorSettingsSms,
	InputOperatorSettingsTrajectoryProfile,
	InputReleaseNote,
	MarkReleaseNoteAsReadDocument,
	SubscriptionSmsIgnoreWarningDocument,
	ToggleTenantOperatorSettingsBookingsShowPaymentsDocument,
	ToggleTenantWAfallbackSmsDocument,
	UpdateReleaseNoteDocument,
	UpdateTenantLocationOptionsDocument,
	UpdateTenantOperatorSettingsBackupDocument,
	UpdateTenantOperatorSettingsBookingsChildAgeDocument,
	UpdateTenantOperatorSettingsBookingsSourcesDocument,
	UpdateTenantOperatorSettingsCrewDocument,
	UpdateTenantOperatorSettingsDisabledPaymentTypesDocument,
	UpdateTenantOperatorSettingsFlightsDocument,
	UpdateTenantOperatorSettingsMyFlightDocument,
	UpdateTenantOperatorSettingsNotificationsDocument,
	UpdateTenantOperatorSettingsSmsDocument,
	UpdateTenantOperatorSettingsTrajectoryProfileDocument,
} from '../../graphql/generated/gen-types';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class TenantSettingService {
	currentUser;

	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	public getTenantSetting(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetTenantSettingDocument,
		});
	}
	public getAllReleaseNotes(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetAllReleaseNotesDocument,
		});
	}
	public getReleaseNote(releaseNoteId: String): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetReleaseNoteDocument,
			variables: {
				releaseNoteId,
			},
		});
	}
	public getReleaseNotes(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetReleaseNotesDocument,
		});
	}
	public getReleaseNotesCount(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetReleaseNotesCountDocument,
		});
	}
	public getTenantSettingMyFlight(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetTenantSettingMyFlightDocument,
		});
	}

	public getSubscription(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetSubscriptionDocument,
		});
	}

	public getUpUrl(bookingId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetUpUrlDocument,
			variables: {
				bookingId,
			},
		});
	}

	public getDefaultLocation(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetDefaultLocationDocument,
		});
	}

	public updateTenantLocationOptions(options: InputLocationOptions): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantLocationOptionsDocument,
				variables: {
					options,
				},
				refetchQueries: [{ query: GetLocationsDocument }, { query: GetDefaultLocationDocument }],
			})
			.pipe(map((result: any) => result.data));
	}

	public updateTenantOperatorSettingsBackup(settings: InputOperatorSettingsBackup): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsBackupDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}

	public updateTenantOperatorSettingsSms(settings: InputOperatorSettingsSms): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsSmsDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsMyFlight(settings: InputOperatorSettingsMyFlight): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsMyFlightDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsFlights(settings: InputOperatorSettingsFlights): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsFlightsDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsCrew(settings: InputOperatorSettingsCrew): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsCrewDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsNotifications(settings: InputOperatorSettingsNotifications): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsNotificationsDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsBookingsChildAge(childAge: number): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsBookingsChildAgeDocument,
				variables: {
					childAge,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsBookingsSources(sources: string[]): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsBookingsSourcesDocument,
				variables: {
					sources,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsDisabledPaymentTypes(disabledPaymentTypes: [string]): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsDisabledPaymentTypesDocument,
				variables: {
					disabledPaymentTypes,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public toggleTenantWAfallbackSMS(): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: ToggleTenantWAfallbackSmsDocument,
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public toggleTenantOperatorSettingsBookingsShowPayments(): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: ToggleTenantOperatorSettingsBookingsShowPaymentsDocument,
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public updateTenantOperatorSettingsTrajectoryProfile(
		settings: InputOperatorSettingsTrajectoryProfile[],
		flightId: string
	): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateTenantOperatorSettingsTrajectoryProfileDocument,
				variables: {
					settings,
				},
				refetchQueries: [{ query: GetTenantSettingDocument }, { query: GetFlightDocument, variables: { flightId } }],
			})
			.pipe(map((result: any) => result.data));
	}

	public subscriptionSmsIgnoreWarning(ignore: Boolean): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SubscriptionSmsIgnoreWarningDocument,
				variables: {
					ignore,
				},
				refetchQueries: [{ query: GetSubscriptionDocument }],
			})
			.pipe(map((result: any) => result.data));
	}

	public addReleaseNote(releaseNote: InputReleaseNote): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: AddReleaseNoteDocument,
				variables: {
					releaseNote,
				},
				refetchQueries: [{ query: GetAllReleaseNotesDocument }],
			})
			.pipe(map((result: any) => result.data));
	}

	public updateReleaseNote(id: string, releaseNote: InputReleaseNote): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateReleaseNoteDocument,
				variables: {
					id,
					releaseNote,
				},
				refetchQueries: [{ query: GetAllReleaseNotesDocument }],
			})
			.pipe(map((result: any) => result.data));
	}

	public deleteReleaseNote(id: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteReleaseNoteDocument,
				variables: {
					id,
				},
				refetchQueries: [{ query: GetAllReleaseNotesDocument }],
			})
			.pipe(map((result: any) => result.data));
	}

	public markReleaseNoteAsRead(releaseNoteIds: string[]): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: MarkReleaseNoteAsReadDocument,
				variables: {
					releaseNoteIds,
				},
				refetchQueries: [{ query: GetReleaseNotesCountDocument }, { query: GetReleaseNotesDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public createIcsId(): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CreateIcsIdDocument,
				refetchQueries: [{ query: GetTenantSettingDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
	public createIcsIdUser(userId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CreateIcsIdUserDocument,
				variables: {
					userId,
				},
				refetchQueries: [{ query: GetUserDocument }],
			})
			.pipe(map((result: any) => result.data));
	}
}
