<mat-card appearance="outlined" style="overflow-y: auto">
	<mat-card-header>
		<mat-card-title>{{ 'FLIGHTS.edit_wa' | translate | capitalize }}</mat-card-title>
	</mat-card-header>
	<mat-chip-set *ngIf="getNumberOfLanguages() > 1" style="margin-left: 20px">
		<mat-chip *ngIf="languages.nl > 0" [selected]="langSelected === 'nl'" (click)="updateLang('nl')">nl ({{ languages.nl }})</mat-chip>
		<mat-chip *ngIf="languages.fr > 0" [selected]="langSelected === 'fr'" (click)="updateLang('fr')">fr ({{ languages.fr }})</mat-chip>
		<mat-chip *ngIf="languages.en > 0" [selected]="langSelected === 'en'" (click)="updateLang('en')">en ({{ languages.en }})</mat-chip>
		<mat-chip *ngIf="languages.de > 0" [selected]="langSelected === 'de'" (click)="updateLang('de')">de ({{ languages.de }})</mat-chip>
	</mat-chip-set>
	<mat-card-content class="mat-card-content">
		<!-- SMS Form -->
		<form [formGroup]="waDetailForm">
			<div style="display: flex; flex-direction: column; justify-content: space-between; gap: 10px">
				<div style="display: flex">
					<mat-form-field appearance="outline" *ngIf="langSelected === 'nl'" [ngStyle]="{ 'width.px': isMobile ? 360 : 480 }">
						<mat-label *ngIf="!whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp' | translate | capitalize }}</mat-label>
						<mat-label *ngIf="whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp_sms' | translate | capitalize }}</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsNL"></textarea>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="langSelected === 'fr'" [ngStyle]="{ 'width.px': isMobile ? 360 : 480 }">
						<mat-label *ngIf="!whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp' | translate | capitalize }}</mat-label>
						<mat-label *ngIf="whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp_sms' | translate | capitalize }}</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsFR"></textarea>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="langSelected === 'en'" [ngStyle]="{ 'width.px': isMobile ? 360 : 480 }">
						<mat-label *ngIf="!whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp' | translate | capitalize }}</mat-label>
						<mat-label *ngIf="whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp_sms' | translate | capitalize }}</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsEN"></textarea>
					</mat-form-field>
					<mat-form-field appearance="outline" *ngIf="langSelected === 'de'" [ngStyle]="{ 'width.px': isMobile ? 360 : 480 }">
						<mat-label *ngIf="!whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp' | translate | capitalize }}</mat-label>
						<mat-label *ngIf="whatsapp?.fallbackSms">{{ 'FLIGHTS.whatsapp_sms' | translate | capitalize }}</mat-label>
						<textarea cdkTextareaAutosize minRows="4" matInput formControlName="smsDE"></textarea>
					</mat-form-field>
				</div>
				<mat-chip-set>
					@for (chip of chipListSelected; track chip) {
					<mat-chip selected="true" (click)="chipClick(chip, langSelected)">{{ formatChip(chip) }}</mat-chip>
					}
				</mat-chip-set>
				<!-- only show toggle when up is enabled and it is not a locationContactSms -->
				<mat-slide-toggle *ngIf="upEnabled && data.smsTemplate != 'locationContactSms'" formControlName="up">{{
					'FLIGHTS.send_up_url_with_sms' | translate | capitalize
				}}</mat-slide-toggle>
			</div>
		</form>
	</mat-card-content>

	<mat-card-actions class="mat-card-actions">
		<button mat-flat-button (click)="confirm(waDetailForm.value)" color="warn">
			{{ 'FLIGHTS.' + sendTo | translate | capitalize }}
		</button>

		<button mat-stroked-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
