{
	"CHARTS": {
		"last1months": "1 m",
		"last3months": "3 m",
		"last6months": "6 m",
		"last12months": "12 m",
		"last24months": "24 m"
	},
	"VALIDATIONS": {
		"error": "Ein unerwarteter Fehler ist aufgetreten, überprüfen Sie Ihre Eingabe",
		"minimun_one_adult_or_Child_is_required": "Sie benötigen mindestens einen Erwachsenen oder ein Kind",
		"error_tenant_does_not_exist": "der Betreiber existiert nicht",
		"not_possible": "diese Aktion ist nicht möglich",
		"required": "Pflichtfeld",
		"tenant_is_required": "Betreiber ist ein Pflichtfeld",
		"email_is_required": "E-Mail ist ein Pflichtfeld",
		"wrong_password": "falsches Passwort",
		"check_email": "es wurde ein Link zum Zurücksetzen des Passworts gesendet",
		"International_phone_format": "internationales Format erforderlich",
		"email": "ungültige E-Mail Adresse",
		"duplicate_email": "doppelte E-Mail-Adressen sind nicht erlaubt",
		"no_integer": "dies ist keine gültige ganze Zahl",
		"token_has_expired": "der Token ist abgelaufen",
		"wrong_credentials": "falsche Zugangsdaten",
		"date_later_than_today": "das Datum darf nicht länger als 90 Tage zurückliegen",
		"save_or_cancel_updates": "Es wurden Änderungen vorgenommen. Bitte zuerst speichern oder abbrechen.",
		"warning": "Warnung",
		"capacity_must_be_between_0_and_30": "die Kapazität muss zwischen 0 und 100 liegen",
		"validation_errors": "Bitte zuerst die Fehler im Formular beheben",
		"mismatch_location": "der Standort liegt nicht in der Buchungsregion",
		"mismatch_availability": "das Fahrtdatum stimmt nicht mit der Buchungsverfügbarkeit überein",
		"mismatch_start_date": "das Fahrtdatum liegt vor dem Startdatum der Buchung",
		"mismatch_end_date": "das Fahrtdatum liegt nach dem Enddatum der Buchung",
		"mismatch_type": "der Fahrttyp stimmt nicht mit der Buchungsart überein",
		"mismatch_flight": "für eine andere Fahrt gebucht",
		"missing_function": "Diese Funktion steht in Kürze zur Verfügung",
		"no_category": "keine Passagierkategorien gefunden. Gehen Sie zuerst zu den Einstellungen, um 1 oder mehrere Kategorien hinzuzufügen.",
		"no_region": "keine Region gefunden, gehen Sie zuerst zu Regionen, um 1 oder mehrere Regionen hinzuzufügen.",
		"lift_mismatch": "Die Tragkraftberechnung ist aufgrund fehlender Daten nicht möglich",
		"lift_missing_location": "Standort fehlt",
		"lift_missing_balloon_volume": "Hüllvolumen ist unbekannt",
		"lift_missing_balloon_weight": "Ballongewicht ist unbekannt oder unvollständig",
		"lift_missing_pilot": "Pilot ist unbekannt",
		"lift_missing_pilot_weight": "Gewicht des Piloten ist unbekannt",
		"lift_missing_temp_takeoff": "die Starttemperatur ist unbekannt",
		"lift_missing_pressure": "der Luftdruck am Startort ist unbekannt",
		"mobile_missing_signature": "Es wurden nicht alle Passagiere abgefertigt",
		"mobile_missing_forecast": "keine Wettervorhersage verfügbar",
		"mobile_documents": "Ablaufdaten",
		"mobile_doc_insurance": "Versicherung gültig bis",
		"mobile_doc_airworthiness": "Lufttüchtigkeit gültig bis",
		"mobile_doc_license": "Pilotenlizenz gültig bis",
		"mobile_clear": "Löschen",
		"mobile_agree": "Zustimmen",
		"mobile_self_sign": "X",
		"click_to_fly": "Angaben korrekt?",
		"click_to_fly_all": "Alles korrekt?",
		"pax_good_to_fly": "Pax ok",
		"lift_good_to_fly": "Auftrieb ok",
		"fuel_good_to_fly": "Treibstoff ok",
		"weather_good_to_fly": "Wetter ok",
		"docs_good_to_fly": "Dokumente ok",
		"pilot_sign_off": "alle Parameter bestätigen und zur Genehmigung unterschreiben. Zunächst können Kommentare zu den Parametern hinzugefügt werden.",
		"agree": "Zustimmen",
		"redo": "Rückgängig machen",
		"new_version": "neue Version verfügbar",
		"reload_the_app": "Möchten Sie Quick Release jetzt neu starten?",
		"new_features_available": "{0} neue Funktionen verfügbar",
		"show_new_features": "neue Funktionen anzeigen",
		"bookings_with_open_amount": "Buchungen mit offenem Betrag",
		"bookings_to_confirm": "zu bestätigen",
		"bookings_bookmarked": "mit Lesezeichen",
		"cylinder_capacity_must_be_between_0_and_200": "Volumen muss zwischen 0 und 200 liegen",
		"cylinder_weight_must_be_between_0_and_200": "Gewicht muss zwischen 0 und 200 liegen",
		"unauthorized": "Sie haben für diesen Vorgang keine Berechtigung",
		"disconnected": "Verbindung verloren, bitte die Internetverbindung prüfen",
		"possible_duplicates": "Wir haben eventuell eine doppelte Buchung gefunden (gleiche Mailadresse bei anderer offenen Buchung)",
		"possible_duplicates_with_flights": "Wir haben eventuell eine doppelte Buchung auf einer anderen Fahrt gefunden",
		"duplicate_flight": "Ballon ist schon gebucht",
		"duplicate_pilot": "Pilot ist schon gebucht"
	},
	"HINTS": {
		"add_country_code": "Ländervorwahl hinzufügen (+49)",
		"no_contact_selected": "Einen Kontakt wählen",
		"message_filter": "Filter ein/aus",
		"role_can_only_be_set_after_creation": "Die Rolle kann erst nach der Erstellung festgelegt werden",
		"please_wait": "bitte warten"
	},
	"SKEYES": {
		"ballooning_bulletin": "Ballooning Bulletin",
		"load": "Skeyes login",
		"disclaimer": "Ballooning Bulleting ist ein Service von Skeyes.",
		"instructions": "Geben Sie Ihren Skeyes Login und Passwort ein, um das Ballooning Bulletin abzurufen. Nur Piloten mit einem gültigen Abonnement können das Ballooning Bulletin abrufen. Die Daten werden nur verwendet um das Ballooning Bulletin abzurufen und werden nicht gespeichert. Skeyes Nutzungsbedingungen finden Anwendung.",
		"username": "Skeyes Username",
		"password": "Skeyes Password",
		"loaded_by": "geladen von",
		"loading_can_take_up_to_15sec": "das Laden kann bis zu 15 Sekunden dauern",
		"error": "Unerwarteter Fehler, überprüfen Sie alle Felder",
		"slot_warning": "dieses Bulletin gilt möglicherweise nicht für diese Fahrt"
	},
	"APPLICATION": {
		"domain": "Domain",
		"Nl": "Nederlands (dutch)",
		"Fr": "Français (french)",
		"En": "English",
		"Es": "Español (spanish)",
		"Cs": "cesky (czech)",
		"It": "Italiano (Italian)",
		"De": "Deutsch (German)",
		"Pt": "Português (portuguese)",
		"Lv": "Latviešu (Latvian)",
		"date": "Datum",
		"name": "Name",
		"call_sign": "Kennzeichen",
		"capacity": "Kapazität",
		"flight_count": "Fahrten",
		"sku": "sku",
		"unit_count": "Einheiten",
		"unit_cost": "Stückpreis",
		"send_sms": "SMS versenden",
		"receive_sms": "Empfangene SMS",
		"sms_count": "SMS",
		"payments": "Zahlungen",
		"running_month": "laufender Monat",
		"history_balloons": "Verlauf Ballone",
		"history_sms": "Verlauf SMS",
		"total_cost": "Preis",
		"sorry": "Entschuldigung",
		"flights": "Fahrten",
		"calendar": "Kalender",
		"vouchers": "Voucher",
		"bookings": "Buchungen",
		"balloons": "Ballone",
		"crew": "Crew und Piloten",
		"regions": "Regionen",
		"locations": "Standorte",
		"reports": "Berichte",
		"admin": "Admin",
		"releasenotes": "release notes",
		"subscription": "Abonnement",
		"log_sms_in": "eingehende SMS",
		"log_sms_out": "ausgehende SMS",
		"settings": "Einstellungen",
		"cylinders": "Zylinder",
		"language": "Sprache",
		"sms": "SMS",
		"help": "Hilfe",
		"search": "Suche",
		"live_filter_off": "Verwenden Sie die Suchschaltfläche, um zu suchen. Wenn mehr als 25 Suchergebnisse angezeigt werden müssen ist eine Live-Suche nicht möglich",
		"add_booking": "Buchung erstellen",
		"add_voucher": "Voucher erstellen",
		"add_flight": "Fahrt erstellen",
		"privacy_policy": "Datenschutzbestimmungen",
		"terms": "Bedingungen",
		"sign in to use your Quick Release Account": "Melden Sie sich an, um Ihr Quick Release-Konto zu verwenden",
		"your username and password didn't match": "Ihr Benutzername und Ihr Passwort stimmen nicht überein",
		"please try again": "Bitte versuchen Sie es erneut",
		"username": "Benutzername",
		"password": "Passwort",
		"sign_in": "Anmelden",
		"send_PIN": "PIN senden",
		"use_PIN": "PIN verwenden",
		"pin_successfully_sent": "PIN erfolgreich gesendet",
		"use_password": "Passwort verwenden",
		"send_email": "E-Mail senden",
		"email": "E-Mail",
		"send_email_to": "Empfänger auswählen",
		"select_template": "Vorlage auswählen",
		"sent": "gesendet",
		"waiting": "sendet",
		"pin": "PIN",
		"sign_out": "Abmelden",
		"save": "Speichern",
		"save_and_back": "Speichern und zurück",
		"cancel": "Abbrechen",
		"previous": "vorheriger",
		"delete": "löschen",
		"refresh": "aktualisieren",
		"today": "heute",
		"classic": "zurück zur alten Schnittstelle",
		"month": "Monat",
		"week": "Woche",
		"morning_flights": "Morgenfahrten heute",
		"evening_flights": "Abendfahrten heute",
		"morning_flights_tomorrow": "Morgenfahrten morgen",
		"yesterday_flights": "Abendfahrten gestern",
		"action": "Aktion",
		"version": "Version",
		"hide_details": "Details ausblenden",
		"loads_faster": "Das Ausblenden von Details verbessert die Ladegeschwindigkeit der Fahrten",
		"assign": "zuordnen",
		"assigned": "zugewiesen",
		"assign_to": "Fahrt zuweisen",
		"close": "Schließen",
		"apps": "Ressourcen"
	},
	"CALENDAR": {
		"waiting_list": "Warteliste",
		"no_bookings_yet": "noch keine Buchungen",
		"drag_and_drop_reservations_here_to_add_to_this_flight": "ziehen Sie Buchungen hierher, um sie zu dieser Fahrt hinzuzufügen",
		"drag_and_drop_reservations_here_to_remove_from_this_flight": "ziehen Sie Buchungen hierher, um sie von dieser Fahrt zu löschen",
		"good_job": "Gute Arbeit!",
		"all_bookings_are_added_to_a_flight": "alle Buchungen wurden zu einer Fahrt hinzugefügt",
		"you_cannot_drop_bookings_here": "Sie können hier keine Buchungen vornehmen",
		"show_calendar_crew": "Crew-Kalender ansehen",
		"set_month": "gesamten Monat setzen",
		"clear_month": "alles löschen",
		"plan": "plan",
		"refetch": "aktualisieren"
	},
	"ACTORS": {
		"COM": "CPL",
		"NCO": "PPL",
		"NOL": "keine Lizenz",
		"NOA": "kein Zugang",
		"OPR": "Betreiber",
		"CRE": "Pilot/Crew (nur zugewiesene Fahrten)",
		"REP": "Berichtviewer",
		"ASS": "Betreiber Assistent (Fahrten & Reservierungen)",
		"MET": "Wetterfrosch (nur Fahrten)",
		"user_role": "Benutzerrolle",
		"name": "Name",
		"phone": "Telefon (internationales Format: +491721234567)",
		"email": "E-Mail",
		"used_for_login": "für die Anmeldung verwendet",
		"emailNotifications": "E-Mail-Benachrichtigungen",
		"emails_from_the_system_will_be_send_to_this_address": "E-Mails aus dem System werden an diese Adresse gesendet, nicht an die Login-Adresse",
		"driver_license": "Führerschein",
		"driver_license_none": "kein",
		"pilot_license": "Pilotenlizenz ",
		"ground_crew": "Bodenpersonal",
		"language": "Sprache",
		"confirm_message": "Sind Sie sicher, dass Sie löschen möchten?",
		"confirm": "bestätigen",
		"contacts": "Kontakte",
		"update": "Update",
		"delete": "löschen",
		"save": "speichern",
		"add_contact": "Kontakt hinzufügen",
		"crew": "Crew",
		"pilot": "Pilot",
		"add_crew": "Crew hinzufügen",
		"national_license": "nationale Lizenz",
		"part_fcl_license": "PART-FCL-Lizenz",
		"license_group_A": "Gruppe A",
		"license_group_B": "Gruppe B",
		"license_group_C": "Gruppe C",
		"license_group_D": "Gruppe D",
		"license_gas": "Gasballon",
		"license_number": "Lizenznummer",
		"license_expiry_date": "Ablaufdatum der OPC / Lizenz",
		"license_extra_crew": "Lizenz für Extrabesatzung",
		"medical_expiry_date": "Ablaufdatum Medical",
		"weight": "Gewicht",
		"warning_not_current": "Die Gültigkeit läuft in weniger als 30 Tagen ab, überprüfen Sie die Ablaufdaten",
		"error_not_current": "Gültigkeit abgelaufen, überprüfen Sie die Ablaufdaten",
		"assigned_to_flight": "Dieser Fahrt zugewiesen",
		"your_flight": "Deine Fahrt",
		"your_flights": "Deine Fahrten",
		"pilots": "Piloten"
	},
	"BALLOONS": {
		"name": "Name",
		"call_sign": "Kennzeichen",
		"capacity": "Kapazität",
		"total_weight": "Gesamtgewicht",
		"balloons": "Ballone",
		"confirm_message": "Sind Sie sicher, dass Sie löschen wollen?",
		"confirm": "bestätigen",
		"update": "Update",
		"delete": "löschen",
		"save": "speichern",
		"add_balloon": "Ballon erstellen",
		"group": "Gruppe",
		"MLM": "MTM (Minimum Landing Mass / Minimale Landemasse)",
		"MTOM": "MTOM (Maximum Take-Off Mass / Maximale Startmasse)",
		"min_fuel": "durchschnittlicher Verbrauch (Liter pro Stunde)",
		"fuel": "Verbrauch bei 20 °C und maximaler Anzahl von Passagieren",
		"envelope": "Hülle",
		"envelope_manufacturer": "Hersteller Hülle",
		"envelope_type": "Typ Hülle",
		"envelope_volume": "Volumen Hülle",
		"envelope_weight": "Gewicht Hülle",
		"envelope_hours": "Stunden Hülle",
		"envelope_inspection_date": "ARC/Jahreskontrolle gültig bis",
		"balloon_temp": "Temperatur Hülle",
		"burner": "Brenner",
		"burner_manufacturer": "Hersteller Brenner ",
		"burner_type": "Typ Brenner",
		"burner_weight": "Gewicht Brenner",
		"cylinder_hint": "Speichern Sie den Ballon zuerst und fügen Sie dann Zylinder hinzu. Stellen Sie sicher, dass Sie zuerst die Zylinder im System erstellen. Die gleichen Zylinder können auf mehreren Ballonen verwendet werden",
		"cylinder": "Zylinder",
		"cylinder_manufacturer": "Hersteller",
		"cylinder_volume": "Volumen",
		"cylinder_count": "Anzahl",
		"cylinder_empty_weight": "Leergewicht",
		"cylinder_full_weight": "volles Gewicht",
		"cylinder_total_weight": "Gesamtgewicht",
		"cylinder_number": "Nummer",
		"cylinder_serial": "Seriennummer",
		"basket": "Korb",
		"basket_manufacturer": "Hersteller Korb ",
		"basket_type": "Typ Korb",
		"basket_weight": "Gewicht Korb",
		"insurance_company": "Versicherungsgesellschaft",
		"insurance_due_date": "Ablaufdatum der Versicherung",
		"add_cylinder": "Zylinder hinzufügen",
		"error_not_current": "Ballon gegroundet, aktualisieren Sie die Ablaufdaten",
		"warning_not_current": "Ballon läuft in weniger als 30 Tagen ab, aktualisieren Sie die Verfallsdaten"
	},
	"VOUCHERS": {
		"status": "Status",
		"create_booking": "Buchung erstellen",
		"available": "verfügbar",
		"used": "verwendet",
		"expired": "abgelaufen",
		"voucher_type": "Typ",
		"show_all_types": "alle Typen anzeigen",
		"voucher_reference": "Referenz",
		"issue_date": "Ausgabedatum",
		"expiry_date": "Ablaufdatum",
		"contactName": "Kontaktname",
		"contactPhone": "Telefon",
		"contactLanguage": "Sprache",
		"contactEmail": "E-Mail",
		"contact_city": "Stadt",
		"recipient_info": "Details der Person, die den Gutschein erhalten und verwenden wird",
		"buyer_info": "Details der Person, die den Gutschein gekauft hat",
		"details_info": "spezifische Informationen zum Gutschein selbst",
		"buyer": "gekauft von",
		"recipient": "Empfänger",
		"recipientName": "Name des Empfängers",
		"recipientPhone": "Telefon",
		"recipientLanguage": "Sprache",
		"recipientEmail": "E-Mail",
		"recipientAddress": "Adresse",
		"recipientPostalCode": "Postleitzahl",
		"recipientCity": "Stadt",
		"recipientCountry": "Land",
		"details": "details",
		"comments": "Kommentare",
		"paymentAmount": "Betrag",
		"paymentStatus": "Zahlungsstatus",
		"paymentDate": "Zahlungsdatum",
		"adults": "Erwachsene",
		"children": "Kinder",
		"external": "extern",
		"external_source": "Quelle",
		"tickets": "Tickets",
		"voucher": "Voucher",
		"available_vouchers": "verfügbare Voucher",
		"confirm_message": "sind Sie sicher, dass Sie dieses Voucher löschen wollen?",
		"unpaid_vouchers": "Achtung! Es gibt unbezahlte Voucher",
		"IMPORT": {
			"import": "importieren",
			"upload_csv": "CSV hochladen",
			"upload_valid_csv_with_this_header": "eine gültige CSV-Datei mit der folgenden Kopfzeile hochladen",
			"example_with_2_adults": "Beispiel für einen Voucher mit 2 Erwachsenen",
			"choose_file": "Datei auswählen",
			"check_file": "Datei prüfen",
			"check_errors": "Fehler prüfen",
			"not_a_valid_CSV": "dies ist keine gültige CSV-Datei",
			"valid_CSV": "keine Validierungsfehler",
			"click_import": "Import anklicken",
			"number_of_vouchers": "Anzahl der Voucher",
			"import_status": "Importstatus",
			"imported_succesfully": "erfolgreich importiert",
			"imported_with_errors": "Fehler beim Import",
			"finish": "fertigstellen",
			"row": "Zeile",
			"error_more_less_columns": "mehr oder weniger Spalten als erwartet",
			"error_header": "Fehler in der Kopfzeile",
			"error_contact_name": "Fehler in contactName, dieses Feld ist ein Pflichtfeld",
			"error_contact_phone": "Fehler in contactPhone, dieses Feld ist ein Pflichtfeld und hat das Format M:+32...",
			"error_contact_language": "Fehler in contactLanguage, dieses Feld ist ein Pflichtfeld",
			"error_voucher_type": "Fehler in Vouchertyp, dieses Feld ist ein Pflichtfeld",
			"error_voucher_reference": "Fehler in Voucherreferenz, dieses Feld ist ein Pflichtfeld",
			"error_issue_date": "Fehler im Ausstellungsdatum, dieses Feld ist ein Pflichtfeld und hat das Format JJJJ-MM-TT",
			"error_expiry_date": "Fehler im Verfallsdatum, dieses Feld ist ein Pflichtfeld und hat das Format JJJJ-MM-TT",
			"error_payment_date": "Fehler im Zahlungsdatum, dieses Feld ist ein Pflichtfeld und hat das Format JJJJ-MM-TT",
			"error_payment_status": "Fehler im Zahlungsstatus, dieses Feld ist ein Pflichtfeld und die akzeptierten Werte sind OPEN oder PAID",
			"error_payment_type": "Fehler bei der Zahlungsart, dieses Feld ist ein Pflichtfeld und die akzeptierten Werte sind NOPA, CASH, WIRE, BANC, CRED, ONLI",
			"error_adults": "Fehler bei Erwachsenen, dies muss eine Zahl sein",
			"error_children": "Fehler bei Kindern, dies muss eine Zahl sein",
			"error_import": "Fehler beim Import",
			"succes_import": "erfolgreich importiert",
			"cancel": "abbrechen"
		}
	},
	"BOOKINGS": {
		"id": "ID",
		"availability": "Verfügbarkeit",
		"PERI": "Verfügbarkeit zu jedem Datum",
		"DATE": "Verfügbarkeit zu einem bestimmten Datum",
		"MOR": "Morgen",
		"EVE": "Abend",
		"mo": "Mo",
		"tu": "Di",
		"we": "Mi",
		"th": "Do",
		"fr": "Fr",
		"sa": "Sa",
		"su": "So",
		"save": "speichern",
		"child": "Kind",
		"name": "Name",
		"age": "das Alter",
		"transport": "Transport",
		"age_amount": "Jahre",
		"weight": "Gewicht",
		"luggage": "Gepäck",
		"paymentType": "Zahlungsart ",
		"paymentReference": "Zahlungsreferenz ",
		"paymentAmount": "Zahlungsbetrag",
		"paymentStatus": "Zahlungsstatus",
		"paymentDate": "Zahlungsdatum",
		"delete": "löschen",
		"OPEN": "öffnen",
		"UNK": "hinzugefügt",
		"INV": "eingeladen",
		"CON": "bestätigt",
		"completed": "abgeschlossen",
		"CAN": "storniert",
		"CPP": "gewerbliche Privatpassagiere",
		"COP": "gewerbliche Passagiere",
		"NCP": "nicht-gewerbliche Passagiere",
		"booking_date": "Buchungsdatum",
		"days_expired": "{{ d }} Tage abgelaufen",
		"startDate": "Startdatum",
		"endDate": "Enddatum",
		"status": "Status",
		"bookingType": "Buchungsart",
		"bookingCode": "Buchungsreferenz",
		"bookingCodeHint": "Klicken Sie, um die App für Passagiere zu öffnen",
		"bookingCodeMissingEmail": "ohne E-Mail haben die Passagiere keinen Zugriff auf die Buchung in der Passagier-App ",
		"commentsTitle": "Kommentare",
		"comments": "Intern Kommentare",
		"pax_comments": "Passagier Kommentare",
		"region": "Region",
		"source": "Quelle",
		"new_source": "neue Quelle",
		"you_can_manage_all_sources_in_settings": "Sie können alle Quellen in den Einstellungen verwalten",
		"source_validation": "Quelle ist ein Pflichtfeld, mindestens 3, maximal 50 Zeichen und muss eindeutig sein",
		"source_list_validation": "wählen Sie eine Quelle aus der Liste oder erstellen Sie zuerst eine neue Quelle",
		"source_list_empty": "keine Quellen gefunden, bitte zuerst eine Quelle erstellen",
		"locationType": "Standort",
		"REGI": "alle Standorte in einer Region",
		"FIXE": "bestimmter Standort in einer Region",
		"PRIV": "privater Standort",
		"contactPerson": "Kontaktperson",
		"contactName": "Kontaktname ",
		"contactPhone": "Telefonnummer Kontakt (internationales Format: +491721234567)",
		"contactEmail": "E-Mail Kontakt ",
		"contactLanguage": "Sprache Kontakt ",
		"action_needed": "Aktion erforderlich",
		"answer_flight_yes_no": "FAHRT JA oder FAHRT NEIN antworten",
		"flight_yes": "FAHRT JA",
		"flight_no": "FAHRT NEIN",
		"PAID": "bezahlt",
		"REFUNDED": "erstattet",
		"CASH": "bar",
		"COUP": "Voucher (in System)",
		"VOUC": "Voucher",
		"NOPA": "keine Zahlung",
		"WIRE": "Überweisung",
		"BANC": "Kontakt Bank",
		"CRED": "Kreditkarte",
		"ONLI": "online",
		"INVO": "Rechnung",
		"CHEQ": "Scheck",
		"only_available_with_paymenttype_voucher": "nur mit Zahlungsart Voucher",
		"bookings": "Buchungen",
		"booking": "Buchung",
		"confirm": "bestätigen",
		"update": "Update",
		"contact": "Kontakt",
		"passengers": "Passagiere",
		"cancel": "abbrechen",
		"next": "weiter",
		"flightDate": "Fahrtdatum",
		"flightPeriod": "Fahrtzeit",
		"flight_balloon": "Ballon",
		"flight_location": "Startort",
		"flight_pilot": "Pilot",
		"add_passenger": "Passagier hinzufügen",
		"messages": "Nachrichten",
		"no_bookings_found": "keine Buchungen gefunden",
		"inviteCount": "lädt ein",
		"confirm_message": "sind Sie sicher, dass Sie diese Buchung löschen wollen?",
		"always_available": "immer verfügbar",
		"limited_availability": "begrenzte Verfügbarkeit",
		"end_before": "Fahrt vor",
		"passenger": "Passagier",
		"details": "Details",
		"payment_details": "Zahlungen",
		"address_details": "Adressinfo",
		"disability": "Unterstützung erforderlich",
		"category": "Kategorie",
		"address": "Adresse",
		"postalCode": "Postleitzahl",
		"city": "Stadt",
		"country": "Land",
		"signature": "Unterschrift",
		"added_flight": "ausgewählte Fahrt",
		"select_flight": "Fahrt auswählen",
		"no_flight_added": "keine Fahrt ausgewählt",
		"no_flight_data": "Fahrtdaten werden nur angezeigt, wenn der Ort und das Datum feststehen",
		"available_flights": "verfügbare Fahrten",
		"no_available_flights": "keine Fahrten verfügbar",
		"available_balloons": "verfügbare Ballone",
		"no_available_balloons": "keine Ballone verfügbar",
		"totalAmount": "Gesamtbetrag",
		"paid_amount": "bezahlt",
		"openAmount": "offener Betrag",
		"prepayment": "Vorauszahlung",
		"prepaymentDate": "Vorauszahlungsdatum",
		"passengers_to_pay": "zu zahlende(r) Fahrgast/Fahrgäste",
		"mark_paid": "als bezahlt kennzeichnen",
		"remove_from_passengerlist": "aus der Passagierliste entfernen",
		"add_to_passengerlist": "zusätzlichen Passagier hinzufügen",
		"checkin_passengers": "zurück zum Einchecken",
		"view_checked_in": "eingecheckte Pax anzeigen",
		"hide_checked_in": "eingecheckte Pax ausblenden",
		"check_in_all": "alle einchecken",
		"import": "importieren",
		"upload_csv": "CSV hochladen",
		"upload_valid_csv_with_this_header": "eine gültige CSV-Datei mit der folgenden Kopfzeile hochladen",
		"example_in_region_with_id_1_with_2_adults_and_0_children": "Beispiel für eine Buchung mit 2 Erwachsenen in der Region mit Id 1",
		"choose_file": "Datei auswählen",
		"check_file": "Datei prüfen",
		"check_errors": "die folgenden Fehler zu korrigieren",
		"not_a_valid_CSV": "dies ist keine gültige Datei",
		"valid_CSV": "es liegen keine Fehler vor",
		"click_import": "zum Importieren klicken",
		"number_of_bookings": "Anzahl der Buchungen",
		"number_of_objects_upcoming": "Anzahl bevorstehenden",
		"number_of_objects_history": "Anzahl beendet",
		"import_status": "Importstatus",
		"imported_succesfully": "erfolgreich importiert",
		"imported_with_errors": "Fehler beim Import",
		"finish": "fertigstellen",
		"row": "Zeile",
		"error_more_less_columns": "mehr oder weniger Spalten als erwartet",
		"error_header": "Fehler in der Kopfzeile",
		"error_contact_name": "Fehler in contactName, dieses Feld ist ein Pflichtfeld",
		"error_contact_phone": "Fehler in contactPhone, dieses Feld ist ein Pflichtfeld und hat das Format M:+49...",
		"error_contact_language": "Fehler in contactLanguage, dieses Feld ist ein Pflichtfeld",
		"error_region": "Fehler in der Region, dieses Feld ist ein Pflichtfeld und muss die ID der Region enthalten",
		"error_adults": "Fehler bei Erwachsenen, dies muss eine Zahl sein",
		"error_children": "Fehler bei Kindern, dies muss eine Zahl sein",
		"errorImport": "Fehler beim Import",
		"succesImport": "erfolgreich importiert",
		"flight_confirmation": "Fahrtbestätigung",
		"date_filter_is_yyyy-mm-dd": "Datumsfilter = jjjj-mm-tt",
		"select_your_view": "Wählen Sie Ihre Ansicht",
		"VIEW": {
			"standard": "Standardansicht",
			"openAmount": "alle offenen Beträge",
			"toConfirm": "zu bestätigen",
			"bookmarked": "mit Lesezeichen versehen",
			"availableThisYear": "dieses Jahr verfügbar",
			"availableNext30Days": "in den nächsten 30 Tagen verfügbar",
			"flyingThisYear": "in diesem Jahr fahren ",
			"flyingNext30Days": "in den nächsten 30 Tagen fahren",
			"withoutFlight": "ohne Fahrten"
		},
		"flight_confirmed": "Fahrt bestätigt",
		"invited_waiting_answer": "eingeladen, wartet auf Antwort",
		"MERGE": {
			"merge": "zusammenführen",
			"load": "laden",
			"restart": "neustarten",
			"select_booking": "Buchungen auswählen",
			"select_booking_hint": "die Buchung auf der linken Seite wird gelöscht, die Buchung auf der rechten Seite wird gespeichert",
			"merge_booking": "Buchungen zusammenführen",
			"merge_booking_hint": "Ihre Zustimmung geben, bevor Sie speichern und löschen können",
			"booking_id_to_delete": "zu löschende Buchungs-ID",
			"booking_id_to_merge": "zusammenzuführende Buchungs-ID",
			"error_loading": "Fehler beim Laden, wählen Sie eine andere ID oder starten Sie neu",
			"warning_to_switch": "Warnung: Sie entscheiden sich dafür, die letzte Reservierung zu löschen",
			"save_and_delete": "diese Reservierung speichern und löschen",
			"confirm_to_delete": "Zustimmung zur Löschung",
			"replace": "ersetzen",
			"add": "hinzufügen",
			"undo": "rückgängig machen",
			"fields_are_identical": "Felder sind identisch",
			"remove": "entfernen"
		},
		"LOG": {
			"logbook": "Logbuch",
			"outbox": "gesendete E-Mails",
			"only_logs_from_UI": "Protokollierung von Quick Release (nicht von UP oder von der Website)",
			"ADDE": "zur Fahrt hinzugefügt",
			"REMO": "von Fahrt entfernt",
			"CONF": "für Fahrt bestätigt",
			"cancel": "Fahrt storniert, auf Buchungsliste verschoben",
			"COMP": "Fahrt durchgeführt, in die Historie verschoben (abgeschlossen)",
			"INVI": "per SMS zum Fahrt eingeladen",
			"CREA": "Buchung im System angelegt",
			"update": "Buchung aktualisiert (ein oder mehrere Felder)",
			"DELE": "Buchung aus dem System gelöscht",
			"MAIL": "E-Mail gesendet",
			"view_email": "E-Mail anzeigen",
			"flight": "Fahrt"
		},
		"invoice_name": "Rechnungsname",
		"vat_number": "Steuernummer"
	},
	"COMMUNICATIONS": {
		"sent_at": "gesendet am",
		"phone": "Telefon",
		"name": "Name",
		"text": "Text",
		"status": "Status",
		"message_id": "Nachrichten-ID",
		"message_price": "Nachrichtenpreis",
		"network": "Netzwerk",
		"remaining_balance": "Restsaldo",
		"received_at": "erhalten am",
		"msisdn": "msisdn",
		"message_timestamp": "Zeitstempel der Nachricht",
		"keyword": "Stichwort",
		"pilot_invitation_sms": "SMS an Piloten",
		"crew_invitation_sms": "SMS an Crew",
		"passenger_invitation_sms": "SMS an Fahrgäste",
		"cancel_sms": "SMS zum Canceln",
		"reminder_sms": "SMS zur Erinnerung",
		"sms_in": "eingehende SMS",
		"messages": "Nachrichten",
		"flight": "Fahrt",
		"booking": "Buchungen",
		"sms_out": "ausgehende SMS",
		"to": "an",
		"unanswered_messages": "unbeantwortete Nachrichten",
		"mark_all_as_read": "markiere alle {0} Nachrichten als gelesen",
		"mark": "Nachrichten",
		"mark_all": "alles markieren",
		"mark_paid": "als bezahlt markieren",
		"mark_not_paid": "als nicht bezahtlt markieren",
		"messages_as_read": "als gelesen markieren",
		"you": "sie",
		"mail_copy": "Kopie senden",
		"action_succeed": "aktion erfolgreich",
		"accepted": "akzeptiert",
		"delivered": "geliefert",
		"failed": "konnte nicht geliefert werden",
		"unknown": "unbekannter Fehler",
		"retry": "später wiederholen",
		"wrongnumber": "falsche Nummer",
		"none": "keiner",
		"WHATSAPP": {
			"settings": "Einstellungen",
			"whatsapp_OFF": "WhatsApp ist deaktiviert",
			"whatsapp_OFF_hint": "Kontaktieren Sie uns, um WhatsApp zu aktivieren, wenn Sie eine WhatsApp for Business-Nummer haben. WhatsApp über Ihre private Nummer wird nicht unterstützt.",
			"whatsapp_ON": "WhatsApp ist aktiviert",
			"whatsapp_ON_hint": "Alle Nachrichten werden über WhatsApp gesendet. Wählen Sie SMS als Backup, falls WhatsApp für Ihren Kunden nicht verfügbar ist.",
			"whatsapp_TRIAL": "WhatsApp ist im Testmodus",
			"whatsapp_TRIAL_hint": "Sie werden immer aufgefordert, zwischen SMS oder WhatsApp zu wählen.",
			"fallbackSMS": "Fallback auf SMS",
			"fallbackSMS_hint": "verwenden Sie SMS, wenn WhatsApp nicht verfügbar ist",
			"connection": "Verbindung",
			"device_connected_successfully": "Gerät erfolgreich verbunden",
			"connect_your_device": "Verbinden Sie Ihr Gerät",
			"connect": "verbinden",
			"disconnect": "trennen",
			"device": "Gerät",
			"phone": "Telefon",
			"checking_device": "Gerät wird überprüft",
			"not_supported_wa_message": "nicht unterstützte WhatsApp-Nachricht"
		}
	},
	"DASHBOARDS": {
		"flights": "Fahrten",
		"no_flights": "keine Fahrten",
		"upcoming": "bevorstehend",
		"historical": "beendet",
		"this_month": "dieser Monat",
		"bookings": "offene Buchungen",
		"vouchers": "Voucher",
		"open": "offen",
		"completed": "abgeschlossen",
		"expired": "abgelaufen",
		"idea_voting": "Ideenwahl",
		"search": "Suche",
		"payments": "Zahlungen",
		"charts": "Berichte",
		"flights_of_today_and_tomorrow_morning": "alle Fahrten von heute und morgen früh",
		"flights_of_next_7_days": "alle Fahrten der nächtsten 7 Tagen",
		"bookings_to_confirm_bookmarked_and_of_today_or_tomorrow_morning": "alle zu bestätigenden, für heute oder morgen früh gebuchten Buchungen, die neue Reservierungen oder mit ein dashboard-tag",
		"basic_charts_of_reservations_and_flights": "grafische Darstellungen von Buchungen und Fahrten",
		"charts_are_no_longer_shown_on_the_dashboard_please_navigate_to_reports": "grafische Berichte werden nicht mehr auf dem Dashboard angezeigt, bitte navigieren Sie zu den Berichten",
		"navigate_to_reports": "Zu den Berichten wechseln",
		"most_recent_sms_per_contact": "neueste Konversationen",
		"show_all_messages": "alle",
		"show_messags_from_this_flight": "nur diese Fahrt anzeigen",
		"show_messages_from_this_booking": "nur diese Buchung anzeigen",
		"show_unread": "ungelesene"
	},
	"FLIGHTS": {
		"save": "speichern",
		"unknown": "unbekannt",
		"CON": "bestätigt",
		"invited_by_sms": "per SMS eingeladen",
		"DEC": "abgelehnt ",
		"MOR": "Morgen",
		"EVE": "Abend",
		"CPF": "gewerbliche private Fahrt",
		"COF": "gewerbliche Fahrt",
		"NCF": "nicht-gewerbliche Fahrt",
		"PRI": "nicht veröffentlicht",
		"PUB": "veröffentlicht",
		"SCH": "geplant",
		"REA": "fertig",
		"MARK": {
			"SCH": "Planungsstatus",
			"REA": "Ready",
			"CAN": "Fahrt abbrechen",
			"FIN": "Fahrt abschließen"
		},
		"INFO": {
			"SCH": "Packen Sie Ihre Sachen zusammen und machen Sie sich bereit für die Ballonfahrt",
			"REA": "gut gemacht! Diese Fahrt ist bereit zur Freigabe",
			"CAN": "diese Fahrt ist annulliert, Sie können sie aus dem System entfernen",
			"FIN": "diese Fahrt ist abgeschlossen, großartige Arbeit!"
		},
		"checkin": "einchecken",
		"CAN": "annulliert",
		"FIN": "beendet",
		"balloon": "Ballone",
		"date": "Datum",
		"period": "Zeitraum",
		"event": "Event",
		"hour": "Stunde",
		"sunrise": "Sonnenaufgang",
		"sunset": "Sonnenuntergang",
		"flight_type": "Fahrttyp",
		"location": "Standort ",
		"visibility": "Sichtbarkeit",
		"web": "Web",
		"status": "Status",
		"comment": "Kommentar",
		"pilot": "Pilot ",
		"pilot_status": "Status",
		"action_needed": "Aktion erforderlich",
		"ground_crew": "Crew ",
		"pilot_and_crew": "Pilot und Crew",
		"not_enough_data": "nicht genügend Daten",
		"answer_pilot_yes_no": "Mit PILOT JA oder PILOT NEIN antworten.",
		"pilot_yes": "PILOT JA",
		"pilot_no": "PILOT NEIN",
		"Flight date cannot be set in the past": "Fahrtdatum kann nicht in die Vergangenheit gelegt werden",
		"answer_crew_yes_no": "Mit BESATZUNG JA oder BESATZUNG NEIN antworten.",
		"crew_yes": "BESATZUNG JA",
		"crew_no": "BESATZUNG NEIN",
		"flights": "Fahrten",
		"flight_bookings": "Fahrtbuchungen",
		"UNK": "hinzugefügt",
		"INV": "per SMS eingeladen",
		"invite_all": "SMS alle einladen",
		"inviteCount": "lädt ein",
		"invite_count_1": "einladen",
		"mo": "Mo",
		"tu": "Di",
		"we": "Mi",
		"th": "Do",
		"fr": "Fr",
		"sa": "Sa",
		"su": "So",
		"invite": "SMS versenden",
		"mail": "E-Mail",
		"reply": "Geben Sie hier Ihre Nachricht ein",
		"sms": "sms",
		"whatsapp": "WhatsApp",
		"whatsapp_sms": "WhatsApp (backup sms)",
		"confirm": "bestätigen",
		"remove": "entfernen",
		"contactName": "Kontaktname",
		"bookingType": "Buchungsart",
		"region": "Region",
		"passengers": "Passagiere",
		"no_bookings_selected": "keine Buchungen ausgewählt",
		"matching_bookings": "Buchungen",
		"mismatching_bookings": "Buchungen mit Fahrten",
		"mismatchReasons": "Fahrt-Matching",
		"add_all": "alle hinzufügen",
		"add": "hinzufügen",
		"already_added": "bereits hinzugefügt",
		"messages": "Nachrichten",
		"calendar": "Kalender",
		"download_manifest": "Durchführungsplan herunterladen",
		"send_sms_reminder": "SMS-Erinnerung senden",
		"update": "Update",
		"are_you_sure": "sind Sie sicher?",
		"reminder_sms_will_be_sent": "es wird eine Erinnerungs-SMS verschickt",
		"edit_sms": "SMS verfassen",
		"preview_sms": "Vorschau",
		"send": "senden",
		"send_to_pax": "an PAX senden",
		"send_to_pax_and_crew": "an PAX und CREW senden",
		"send_up_url_with_sms": "UP-URL mit SMS senden",
		"sent": "gesendet",
		"message_has_been_sent": "Nachricht wurde gesendet",
		"message_has_not_been_sent": "Nachricht wurde nicht gesendet",
		"not_sent": "nicht gesendet",
		"not_confirmed_bookings_will_be_removed_from_flight": "die folgenden, nicht bestätigten Buchungen werden aus dem Fahrtplan gestrichen",
		"flight_will_be_cancelled": "die Fahrt wird annulliert",
		"flight_will_be_finished": "die Fahrt wird beendet",
		"flight_will_be_reopened": "die Fahrt geht zurück zur Planung",
		"yes_cancel_it": "ja, annullieren Sie es",
		"no_please": "bitte nicht",
		"cancel_sms_will_be_sent": "Annullierung-SMS wird gesendet",
		"not_cancelled": "nicht annulliert",
		"flight_has_not_been_cancelled": "die Fahrt wurde nicht annulliert",
		"confirm_message": "sind Sie sicher, dass Sie diese Fahrt annullieren wollen?",
		"flight_pilot_in_command": "verantwortlicher Pilot",
		"invite_by_sms": "SMS versenden",
		"unsupported_phonenumber": "nicht unterstützte Rufnummer",
		"sms_unclear": "SMS unklar",
		"no_pilot_in_command_selected": "kein verantwortlicher Pilot ausgewählt",
		"flight_crew": "Crew",
		"no_crew_selected": "keine Crew ausgewählt",
		"available_pilots_and_crew": "verfügbare Piloten und Crew",
		"select_pilot": "Pilot auswählen",
		"select_crew": "Crew auswählen",
		"other_flight": "andere Fahrt",
		"add_as_crew": "als Crew hinzufügen",
		"add_as_pilot": "als Pilot hinzufügen",
		"warning": "Warnung",
		"creating_a_flight_can_lead_to_costs": "diese Maßnahme kann Kosten verursachen",
		"proceed": "fortfahren",
		"cancel": "abbrechen",
		"passenger_count": "Anzahl Passagiere",
		"crew_count": "Anzahl Crewmitglieder",
		"type": "Typ",
		"no_pilot": "kein Pilot",
		"seats": "Sitze ",
		"delete": "löschen",
		"set_ready": "fertig",
		"address": "Adresse",
		"postalCode": "ZIP",
		"city": "Stadt",
		"country": "Land",
		"manifest": "Fahrtdurchführungsplan",
		"certificates": "Zertifikate",
		"takeoffTime": "Abfahrtzeit",
		"landingLocation": "Landeort",
		"landingDetails": "Details zur Landung",
		"total_weight": "Gesamtgewicht",
		"landingTime": "Landezeit",
		"flightTime": "Fahrtzeit",
		"and": "und",
		"name": "Name",
		"ref": "Ref.",
		"amount": "Betrag (EUR)",
		"weight": "Gewicht",
		"signature": "Unterschrift",
		"reminder_sms": "Erinnerungs-SMS",
		"also_to_pilot_and_crew": "ebenso an Pilot und Crew",
		"cancel_sms": "SMS annullieren",
		"no_flights_found": "keine Fahrten gefunden",
		"no_location": "kein Standort",
		"show": "zeigen",
		"hide": "ausblenden",
		"lift": "Tragkraft",
		"lift_needed": "erforderliche Tragkraft",
		"lift_available": "verfügbare Tragkraft",
		"lift_spare": "verbleibende Tragkraft",
		"takeoff_location": "Startort",
		"temp": "Temperatur",
		"takeoff_height": "Startplatzhöhe",
		"maximum_height": "maximale Höhe",
		"temp_takeoff": "Starttemperatur",
		"temp_max": "Maximalhöhe Temperatur",
		"weather_qfe": "Startdruck (QNH)",
		"weather_pmax": "Maximalhöhe Druck",
		"balloon_volume": "Hüllenvolumen",
		"balloon_temp": "Maximale Temperatur Hülle",
		"create_add": "zur neuen Fahrt hinzufügen",
		"weather": "Wetter",
		"copy_weather_to": "Wetter kopieren zu",
		"use_meteoblue": "Meteoblue verwenden",
		"skeyes_ballooning_bulletin": "skeyes ballooning bulletin",
		"use_skeyes": "Skeyes verwenden",
		"weather_forecast": "Wettervorhersage",
		"no_weather_forecast": "keine Wettervorhersage verfügbar",
		"windSummary": "Zusammenfassung der Wettervorhersage",
		"weather_source": "Quelle",
		"weather_forecast_date": "Datum der Wettervorhersage",
		"vfr_visual": "Sichtbarkeit",
		"vfr_clouds": "Wolken",
		"wind_gl": "Wind Bodenhöhe",
		"wind_gl_short": "Wind GL",
		"wind_dir": "Richtung",
		"wind_gl_direction": "Windrichtung Bodenhöhe",
		"wind_10": "Wind 1000FT",
		"wind_10_direction": "Windrichtung 1000FT",
		"wind_20": "Wind 2000FT",
		"wind_20_direction": "Windrichtung 2000FT",
		"wind": "Wind",
		"clear": "klar",
		"fuel_spare": "Treibstoffreserve",
		"planned_flight_time": "geplante Fahrtzeit",
		"spare_flight_time": "reserve Fahrtzeit",
		"maximum_flight_time": "verfügbare Fahrtzeit",
		"select_balloon": "den Ballon auswählen",
		"select_location": "den Standort auswählen",
		"offsets": "Sonnenaufgang-/Sonnenuntergang-Offsets ändern",
		"flight_hour_will_be_calculated_based_on_sunset_minus_the_offset_minutes": "Die Stunde der Fahrt wird auf der Grundlage des Sonnenuntergangs (oder Sonnenaufgangs) abzüglich der Offset-Minuten berechnet, abgerundet auf eine halbe Stunde. Negative Werte sind zulässig (im Falle des Sonnenaufgangs).",
		"offset_sunrise": "Offset-Sonnenaufgang (Minuten)",
		"offset_sunset": "Offset-Sonnenuntergang (Minuten)",
		"payment_overview": "Zahlungsübersicht",
		"go_back": "geh zurück",
		"traject": {
			"trajectory": "Trajektorie",
			"custom_flight_profile": "benutzerdefiniertes Fahrtprofil",
			"duration": "Dauer",
			"duration_is_required": "Dauer ist erforderlich",
			"level": "Höhe",
			"level_is_required": "Höhe ist erforderlich",
			"save": "speichern",
			"no_trajectory_without_meteo": "keine Trajektorie ohne Wettervorhersage",
			"update_custom_profile": "benutzerdefiniertes Profil aktualisieren",
			"refresh_weather": "Wetter aktualisieren",
			"talking_to_meteoblue": "mit meteoblue sprechen",
			"refresh_hint": "meteoblue aktualisiert ihr Wettermodell alle 12 Stunden. Schnellere Updates haben keinen Sinn und sind deaktiviert."
		},
		"LANDING": {
			"landing": "Landung",
			"landing_details": "details",
			"map": "map",
			"no_details": "keine Details verfügbar",
			"landing_data": "Landedaten können vorerst nur im mobilen Check-in-Bildschirm geändert werden",
			"refuelVolume": "Getankte Gasmenge"
		},
		"FILTER": {
			"date_range_filter": "Datumsbereichsfilter",
			"from": "von",
			"till": "bis",
			"excluded": "ausgeschlossen",
			"no_date_filter_active": "kein aktiver Datumsfilter",
			"enter_a_date_range": "Geben Sie einen Datumsbereich ein",
			"start_date": "Startdatum",
			"end_date": "Enddatum",
			"month": "Monat",
			"week": "Woche",
			"day": "Tag",
			"flights": "Fahrten"
		},
		"flight_no_bookings": "Diese Fahrt hat noch keine Buchungen",
		"mor_flight": "Morgenfahrt",
		"eve_flight": "Abendfahrt",
		"mor_flights": "Morgenfahrten",
		"eve_flights": "Abendfahrten",
		"no_mor_flights": "keine Morgenfahrten",
		"no_eve_flights": "keine Abendfahrten",
		"responded_pilots": "Piloten mit Rückmeldung",
		"delete_flight": "Fahrt löschen",
		"toggle_visibility": "Sichtbarkeit ändern",
		"tags": "tags",
		"COPY": {
			"copy_flight": "Flug kopieren",
			"select_multiple_dates": "Wählen Sie mehrere Daten aus, um denselben Flug mit demselben Standort, Ballon, Piloten und Crew zu kopieren",
			"selected_dates": "ausgewählte Daten",
			"no_tags": "keine Tags",
			"copy": "kopieren"
		},
		"PILOTANDCREW": {
			"selected": "ausgewählt",
			"most_used": "am häufigsten verwendet",
			"this_is_the_most_used_pilot_and_crew": "dies ist der am häufigsten verwendete Pilot und die Crew für diesen Ballon",
			"accept": "akzeptieren",
			"all_pilots_and_crew": "alle Piloten und Crew",
			"select": "auswählen"
		}
	},
	"MANIFEST": {
		"fuel_consumption": "Treibstoffverbrauch",
		"fuel_needed": "benötigter Treibstoff",
		"fuel_available": "verfügbarer Treibstoff ",
		"load_chart_and_passenger_list": "Tragkraftberechnung und Passagierliste",
		"passenger_list": "Passagierliste",
		"weather_conditions": "Wetterbedingungen",
		"fuel_calculations": "Treibstoffberechnung",
		"load_chart": "Tragkraftberechnung",
		"after_flight": "Nach der Fahrt",
		"visible_defects": "Sichtbare Defekte",
		"yes_no": "Ja/Nein",
		"date": "Datum",
		"signature": "Unterschrift",
		"defect": "Defekt",
		"action": "Aktion",
		"certificate": "Zertifikat",
		"authority": "Behörde",
		"initials": "Initialen",
		"confirmation": "Damit wird bestätigt, dass die angegebenen Aktionen gemäß PART-ML ausgeführt wurden und das Luftfahrzeug für die nächsten Fahrt freigegeben wurde."
	},
	"LOCATIONS": {
		"name": "Name",
		"description": "Beschreibung",
		"region": "Region",
		"address": "Adresse",
		"postalCode": "Postleitzahl",
		"city": "Stadt",
		"country": "Land",
		"height": "Abflughöhe",
		"maximum height": "maximale Höhe",
		"locations": "Standorte",
		"confirm_message": "Sind Sie sicher, dass Sie löschen wollen?",
		"confirm": "bestätigen",
		"update": "Update",
		"delete": "löschen",
		"regions": "Regionen",
		"save": "speichern",
		"add_location": "Standort hinzufügen",
		"add_region": "Region hinzufügen",
		"hot_airfield": "Flugplatz",
		"coordinates": "Koordinaten",
		"geolocate": "geolokalisieren",
		"no_results": "keine Ergebnisse",
		"contact": "Kontakt",
		"takeoff_location": "Standort",
		"takeoff_location_subtitle": "Die Koordinaten des Startortes werden für die Wettervorhersage, die Trajektorie und die Auftriebsberechnung verwendet.",
		"meetingpoint": "Treffpunkt",
		"meetingpoint_subtitle": "Die Adresse des Treffpunktes wird den Passagieren (UP) mitgeteilt.",
		"same_as_takeoff": "gleich wie Startort",
		"meetingpointAddress": "Treffpunkt Adresse",
		"CONTACT": {
			"name": "Name",
			"type": "Typ",
			"comment": "Kommentar",
			"phone": "Telefon",
			"email": "Email",
			"when_to_contact": "wann zu kontaktieren",
			"how_to_contact": "wie zu kontaktieren",
			"NOMSG": "person wird nicht kontaktiert",
			"MAIL": "email",
			"SMS": "sms",
			"mail": "wenn die Fahrt vorbereitet wird, wird die Kontaktperson eine email erhalten",
			"sms": "wenn die Fahrt vorbereitet wird, wird die Kontaktperson eine sms erhalten",
			"no_message": "die Kontaktperson wird nicht kontaktiert",
			"add_sms_template": "sms template hinzufügen",
			"add_mail_template": "email template hinzufügen",
			"mail_template": "email template",
			"sms_template": "sms template",
			"warning_email": "sie haben noch keine email an die kontaktperson gesendet",
			"warning_sms": "sie haben noch keine sms an die kontaktperson gesendet"
		}
	},
	"FORECAST": {
		"no_mobile": "Meteo wird derzeit nur auf größeren Bildschirmen unterstützt",
		"select_favorite": "Wählen Sie einen Standort als Favoriten aus, um die meteoblue-Wettervorhersage anzuzeigen",
		"no_forecast": "Für diesen Standort ist kein Meteo verfügbar. Überprüfen Sie Breiten- und Längengrad",
		"remove_favorite": "Favorit entfernen",
		"mark_favorite": "Favorit markieren",
		"favorite_description": "Dieser Ort ist Ihr Lieblingsort. Die meteoblue Wettervorhersage ist auf dem Dashboard für diesen Standort verfügbar",
		"non_favorite_description": "Dieser Ort ist nicht Ihr Lieblingsort. Markieren Sie, dass es Ihr Favorit ist, um die meteoblue-Wettervorhersage auf dem Armaturenbrett anzuzeigen",
		"meteo": "meteo",
		"time": "time",
		"summary": "summary"
	},
	"REGIONS": {
		"name": "Name",
		"locations": "Standorte",
		"no_locations_yet": "noch keine Standorte",
		"name_required": "Name erforderlich",
		"change_color": "Klicken Sie hier, um die Regionsfarbe zu ändern",
		"reset_color": "farbe zurücksetzen",
		"confirm_color": "farbe bestätigen",
		"cancel_color": "farbe abbrechen"
	},
	"EVENTS": {
		"new_event": "neues Event",
		"information": "ein Event an einem bestimmten Datum hinzufügen oder die Feiertage laden",
		"new_crew_event": "neuer Termin",
		"crew_information": "einen besetzten Termin hinzufügen (nicht verfügbar für Flüge oder Besatzung)",
		"holidays": "Ferien",
		"load_public_holidays_of_year_from_country": "Feiertage von {0} aus {1} laden",
		"date": "Datum",
		"description": "Beschreibung",
		"save": "speichern",
		"cancel": "abbrechen",
		"required": "obligatorisch",
		"crew": "Besatzung",
		"NO": "nicht verfügbar",
		"YES": "verfügbar",
		"MAYBE": "evtl. verfügbar",
		"UNK": "verfügbarkeit unbekannt"
	},
	"SETTINGS": {
		"user_default_language": "Standardsprache des Benutzers",
		"submit": "einreichen",
		"preferences_language": "Sprache",
		"preferences_sms": "SMS",
		"smsTitle": "Titel",
		"smsTemplate": "Vorlage",
		"pilotInvitationSms": "Einladungs-SMS Pilot",
		"example": "Beispiel",
		"update": "Update",
		"crewInvitationSms": "Einladungs-SMS Crew",
		"passengerInvitationSms": "Einladungs-SMS Passagiere",
		"cancelSms": "Annulierungs-SMS",
		"reminderSms": "Erinnerungs-SMS",
		"locationContactSms": "Standortkontakt-SMS",
		"emailTitle": "Titel",
		"no_emailTitle": "kein Titel",
		"emailType": "type",
		"emailTo": "email to",
		"emailAttachments": "Anlagen",
		"emailSubject": "Betreff",
		"emailBody": "Körper",
		"emailTest": "E-Mail-Test",
		"mailFromEmail": "E-mail-Antwort Adresse",
		"mailFromName": "E-Mail-Antwort an E-Mail",
		"mailReplyToEmail": "Email Reply To Email",
		"mailAccountantName": "E-Mail-Name des Buchhalters",
		"mailAccountantEmail": "E-Mail-Adresse des Buchhalters",
		"bookingConfirmEmail": "E-Mail-Bestätigung der Buchung",
		"bookingFlightCancelEmail": "E-Mail Ende der Fahrt",
		"bookingFlightConfirmEmail": "E-Mail Bestätigung der Fahrt",
		"crewFlightEmail": "E-Mail Fahrtdaten für die Crew",
		"crewOtpEmail": "E-Mail-Login für die Crew",
		"pilotFlightEmail": "E-Mail Fahrtdaten für den Piloten",
		"flight_email": "E-Mail Fahrtdaten",
		"locationContactEmail": "Standortkontakt-E-Mail",
		"flight_email_passengers": "E-Mail Passagiere",
		"flight_sms_passengers": "SMS Passagiere",
		"load_default": "einen Standardtext laden",
		"passenger_categories": "Passagierkategorien",
		"category_name": "Name",
		"category_description": "Beschreibung",
		"category_child": "Kind",
		"category_price": "Preis",
		"category_default": "Standard",
		"delete": "löschen",
		"mobile_questions": "Fragen Fahrgäste (mobil)",
		"question": "Frage",
		"kind": "Art",
		"active": "aktiv",
		"title": "Titel",
		"description": "Beschreibung",
		"language": "Sprache",
		"texts": "Texte",
		"name": "Name",
		"address": "Adresse",
		"email": "E-Mail",
		"weight": "Gewicht",
		"luggage": "Gepäck",
		"child": "Kind",
		"accompany": "begleiten",
		"pay": "bezahlen",
		"signature": "Unterschrift",
		"optional": "optional",
		"mandatory": "obligatorisch",
		"order": "Bestellung",
		"titles": "Fragen",
		"descriptions": "Fragen Texte",
		"yes": "ja",
		"no": "nein",
		"nl": "niederländisch",
		"fr": "französisch",
		"en": "englisch",
		"de": "deutsch",
		"id": "ID",
		"download_sample": "Beispiel herunterladen",
		"drag_and_drop_file_here": "drag and drop file here",
		"browse_for_file": "Nach Datei suchen",
		"operator_settings": "Bedienereinstellungen",
		"operator_backup_and_sync": "backup en sync",
		"operator_bookings": "reservierung einstellungen",
		"operator_flights": "fahrt einstellungen",
		"operator_crew": "crew einstellungen",
		"operator_myflight": "UP (Passengers App) einstellungen",
		"export": "export",
		"you_can_activate_a_daily_backup": "Sie können einen täglichen Export in HTML an eine bestimmte E-Mail-Adresse senden. Sobald Sie den Export aktivieren, erhalten Sie sofort einen Export an die angegebene E-Mail-Adresse, dann jeden Tag gegen 4 Uhr morgens",
		"daily_backup": "täglicher Export",
		"backup_email": "E-Mail-Adresse",
		"only_update_when_inactive": "Sie können die E-Mail-Adresse nur ändern, wenn der Export inaktiv ist",
		"exported_files": "exportierte Dateien",
		"bookings": "alle offenen Buchungen",
		"flights": "alle geplanten Fahrten",
		"vouchers": "alle Gutscheine",
		"flights_bookings_passengers": "alle geplanten Flüge mit ihren Buchungen und Passagieren",
		"calendarUrl": "ICS-URL für den Kalender",
		"you_can_subscribe_to_all_flights_in_your_personal_calendar": "Sie können alle Flüge in Ihrem persönlichen Kalender abonnieren",
		"geticsurl": "ICS-URL generieren",
		"readthedocs": "Dokumentation lesen",
		"crew": {
			"title": "Crew",
			"shareBookings": "Die Crew kann die Details der Buchungen einer Fahrt im Dashboard sehen",
			"shareBookingsHint": "Wenn dies nicht aktiviert wird, kann die Crew nur die Anzahl der Passagiere auf einer Fahrt im Dashboard sehen",
			"allowFlightSelection": "Crew kann Fahrten im Kalender auswählen",
			"allowFlightSelectionHint": "Wenn dies nicht angekreuzt ist, kann die Crew die Fahrten nur im Kalender sehen, aber nicht auswählen"
		},
		"operator_notifications": "Benachrichtigungen",
		"notifications": {
			"title": "E-Mail-Benachrichtigungen",
			"calendar": "Erhalten Sie eine E-Mail, wenn sich die Verfügbarkeit von Crew und Piloten ändert",
			"calendarHint": "wenn dies aktiviert ist, sendet das System automatisch eine übersichtliche E-Mail an den Betreiber und die Crew 5 Minuten, nachdem die Crew oder der Pilot eine oder mehrere Verfügbarkeiten geändert hat",
			"calendarClosing": "E-Mail-Abschlusstext",
			"calendarClosingHint": "Dieser Abschlusstext wird jeder E-Mail hinzugefügt",
			"flightCrew": "Senden Sie eine E-Mail, wenn Sie einen Piloten oder ein Crewmitglied zu einem Flug hinzufügen",
			"flightCrewHint": "wenn dies aktiviert ist, wird automatisch eine E-Mail an den Piloten und die Crew gesendet, wenn Sie sie zu einem Flug hinzufügen oder entfernen",
			"save": "speichern"
		},
		"booking": {
			"title": "Buchung",
			"childAge": "Kinderalter",
			"childAgeHint": "Jeder Passagier mit diesem Alter oder jünger wird automatisch als Kind gekennzeichnet",
			"disabledPaymentTypes": "deaktivierte Zahlungsarten",
			"disabledPaymentTypesHint": "Wenn Sie eine Zahlungsart deaktivieren, wird sie nicht mehr auf der Buchungsseite angezeigt.",
			"none_of_the_paymentTypes_are_disabled": "keine der Zahlungsarten ist deaktiviert",
			"disabledPaymentTypesSave": "Zahlungsarten speichern",
			"sources": "Quellen"
		},
		"flight": {
			"title": "Fahrten",
			"sunriseOffset": "Sonnenaufgang Offset",
			"sunriseOffsetHint": "Die Fahrtzeit wird auf Basis des Sonnenaufgangs minus der Anzahl der Offset-Minuten berechnet, abgerundet auf die untere halbe Stunde.",
			"sunsetOffset": "Sonnenuntergang Offset",
			"sunsetOffsetHint": "Die Fahrtzeit wird auf Basis des Sonnenuntergangs minus der Anzahl der Offset-Minuten berechnet, abgerundet auf die untere halbe Stunde.",
			"meetingTimeOffset": "Treffpunktzeit Offset (diese Funktion ist noch nicht verfügbar)",
			"meetingTimeOffsetHint": "Die Treffpunktzeit für die Fahrt wird auf Basis der Fahrtzeit minus der Anzahl der Offset-Minuten berechnet. Wenn 0, wird die Treffpunktzeit nicht angezeigt.",
			"sunrise": "Sonnenaufgang",
			"sunset": "Sonnenuntergang",
			"hourMeeting": "Treffpunktzeit",
			"hour": "Fahrtzeit",
			"save": "Offsets speichern"
		},
		"myflight": {
			"title": "Quick Release UP (Passengers App)",
			"autoConfirm": "bestätigt die Ballonfahrt automatisch, wenn er über UP angefordert wird",
			"autoConfirmHint": "Wenn dies nicht aktiviert wird, muss der Bediener die Ballonfahrt manuell bestätigen, wenn er über UP hinzugefügt wird",
			"endOfSeason": "Saisonende",
			"endOfSeasonHint": "Wenn dies nicht aktiviert wird, können die Passagiere Fahrten für die gesamte Saison anfordern",
			"flights": "Welche Ballonfahrten können die Passagiere anfordern?",
			"ALL": "alle Ballonfahrten, unter Berücksichtigung der Tag-Einschränkungen",
			"REGIONAL": "nur regionale Ballonfahrten (Ballonfahrten, die in der Region des Passagier starten)",
			"NONE": "keine Ballonfahrten (Passagiere können keine Ballonfahrten anfordern)",
			"parameters": "Parameter",
			"branding": "Branding",
			"logo": "Logo",
			"logoHint": "Laden Sie ein Logo hoch, das in der UP-App angezeigt wird",
			"drag_and_drop_file_here": "Ziehen Sie Ihre .jpg- oder .png-Datei per Drag&Drop hierher (max. 500 kB)",
			"colors": "Farben",
			"colorsHint": "Klicken Sie auf jede Farbe, um sie an Ihren eigenen Stil anzupassen. Sie können eine Farbe aus der Palette Ihres Logos in dem Pop-up auswählen",
			"example": "Beispiel",
			"reset": "Farben zurücksetzen",
			"save": "Farben und Logo speichern",
			"primary_background_color": "Hintergrundfarbe der App",
			"secondary_background_color": "Hintergrundfarbe der Kacheln",
			"primary_foreground_color": "Textfarbe",
			"secondary_foreground_color": "Farbe des Informationstextes",
			"invalid_file_type_or_size": "Ungültiger Dateityp oder zu große Datei"
		},
		"sms": {
			"footerEnabled": "footer zu SMS hinzufügen",
			"footerEnabledHint": "Wenn dies nicht aktiviert ist, wird die Fußzeile nicht allen Einladungs-SMS hinzugefüg"
		}
	},
	"REPORTS": {
		"flights": "Ballonfahrten",
		"bookings_open": "Offene Buchungen",
		"bookings": "Neue Buchungen",
		"vouchers": "Voucher",
		"payments": "Zahlungen",
		"sms": "SMS"
	},
	"MAIL": {
		"hide": "Details ausblenden",
		"show": "Details anzeigen",
		"sent": "gesendet"
	},
	"SUBSCRIPTION": {
		"sms_available": "im Abonnement erhältlich",
		"sms_sent": "SMS gesendet",
		"pct_sms_used": "% Ihrer SMS wurden verwendet",
		"sms_info": "Kontaktieren Sie uns, um zusätzliche SMS zu bestellen, sobald Sie 95% der verfügbaren SMS in Ihrem Abonnement verwendet haben",
		"sms_warning": "Sie haben 95% oder mehr Ihrer verfügbaren SMS verwendet.",
		"order_sms": "Jetzt neue SMS bestellen",
		"sms_ignore_warning": "Benachrichtigung über SMS-Nutzung ausblenden",
		"pct_plan_used": "% Ihrer Sitzplätze sind belegt",
		"plan_seats_used": "Gesamtballonkapazität",
		"plan_valid_until": "gültig bis",
		"plan_info": "Kontaktieren Sie uns, um zu einem höheren Tarif zu wechseln, wenn die Anzahl der Lizenzen zu niedrig ist, oder um Ihr aktuelles Abonnement zu verlängern"
	},
	"TAGS": {
		"delete": "tag entfernen",
		"enabled": "aktiviert",
		"enable_tag": "Tag aktivieren",
		"disabled": "deaktiviert",
		"disable_tag": "Tag deaktivieren",
		"save": "tag speichern",
		"new_tag": "neues Tag erstellen",
		"tag_name": "tag-Name",
		"tag_type": "tag-Typ",
		"select_tag_color": "Wähle Farbe",
		"color": "Farbe",
		"tag_already_added": "Tag bereits hinzugefügt",
		"edit": "Tag bearbeiten",
		"flight_tags": "Fahrt-Tags",
		"tags": "tags",
		"show_on_dashboard": "auf dem Dashboard anzeigen",
		"allowedFlightTags": "erlaubte",
		"not_restricted": "Alle Buchungen erlaubt",
		"all_flights_allowed": "alle Fahrten erlaubt, es sei denn, spezifische Buchungstags sind erforderlich",
		"onlyAllowedFlightTags": "nur diese Buchungstags sind erlaubt",
		"no_bookings_allowed": "keine Buchungen erlaubt"
	}
}
