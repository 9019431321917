import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TranslateCustomService {
	en = require('../../../assets/i18n/en.json');
	nl = require('../../../assets/i18n/nl.json');
	du = require('../../../assets/i18n/du.json');
	fr = require('../../../assets/i18n/fr.json');
	de = require('../../../assets/i18n/de.json');

	constructor() {}

	translate(text: string, lang?: string) {
		let translations = this.en;
		switch (lang) {
			case 'du':
				translations = this.du;
				break;
			case 'nl':
				translations = this.nl;
				break;
			case 'fr':
				translations = this.fr;
				break;
			case 'de':
				translations = this.de;
				break;
		}
		const domain = text.substr(0, text.indexOf('.'));
		const placeholder = text.substr(domain.length + 1);

		if (domain && placeholder) {
			const translation = translations[domain][placeholder];
			return translation;
		} else {
			return text;
		}
	}

	capitalizeAndTranslate(text: string, lang?: string) {
		let output = this.translate(text, lang);
		output = output.charAt(0).toUpperCase() + output.slice(1);
		return output;
	}
}
