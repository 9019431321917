import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Tag, TagInput, TagType } from 'src/app/core/graphql/generated/gen-types';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { TagNewComponent } from '../tag-new/tag-new.component';

@Component({
	selector: 'app-tag-table',
	templateUrl: './tag-table.component.html',
	styleUrls: ['./tag-table.component.scss'],
})
export class TagTableComponent implements OnInit {
	@Input() type: TagType = null;
	dataSource: MatTableDataSource<Tag> = null;
	displayedColumns: string[] = [
		'name',
		'type',
		'color',
		'numberofObjectsUpcoming',
		'numberofObjectsHistorical',
		'allowedFlightTags',
		'dashboard',
		'action',
	];
	isLoading = false;

	tags: Tag[] = [];
	private dialogRef: MatDialogRef<TagNewComponent, any>;

	constructor(private tagService: TagService, private snackBar: SnackBar, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.getTags();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.type) {
			if (changes.type.currentValue !== changes.type.previousValue) {
				this.type = changes.type.currentValue;
				this.getTags();
			}
		}
	}

	private getTags(): void {
		this.isLoading = true;
		if (this.type) {
			this.tagService.getTagsOfType(this.type, true).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getTagsOfType);
					this.tags = tags;
					this.dataSource = new MatTableDataSource(this.tags);
					this.isLoading = false;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
					this.isLoading = false;
				}
			);
		} else {
			this.tagService.getAllTags(true).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getAllTags);
					this.tags = tags;
					this.dataSource = new MatTableDataSource(this.tags);
					this.isLoading = false;
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
					this.isLoading = false;
				}
			);
		}
	}

	disableOrEnableTag(tag: Tag): void {
		let updatedTag: TagInput = {
			tag: tag.tag,
			backgroundColor: tag.backgroundColor,
			type: tag.type,
			enabled: tag.enabled,
		};
		updatedTag.enabled = !updatedTag.enabled;

		this.tagService.createOrUpdateTag(updatedTag, tag.id).subscribe(
			() => {},
			(err) => {
				this.snackBar.openSnackBarError(err.message);
			}
		);
	}

	deleteTag(tag: Tag): void {
		this.tagService.deleteTag(tag).subscribe(
			() => {},
			(err) => {
				this.snackBar.openSnackBarError(err.message);
			}
		);
	}

	editTag(tag: Tag): void {
		this.dialogRef = this.dialog.open(TagNewComponent, {
			data: {
				id: tag.id,
				color: tag.backgroundColor,
			},
			width: '410px',
		});
	}
	setAllowedFlightTags(event) {
		console.log(event);
	}

	listBookingTags(tags) {
		return tags.map((tag) => tag.tag).join(', ');
	}
}
