<mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="width: 100%"></mat-progress-bar>
<mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
	<ng-container matColumnDef="name">
		<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SETTINGS.category_name' | translate | capitalize }}</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			{{ tag.tag }}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="type">
		<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TAGS.tag_type' | translate | capitalize }}</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			{{ 'COMMUNICATIONS.' + tag.type | translate }}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="color">
		<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TAGS.color' | translate | capitalize }}</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			<mat-icon *ngIf="tag.backgroundColor" [ngStyle]="{ color: tag.backgroundColor }">bookmark</mat-icon>
			<div *ngIf="!tag.backgroundColor">{{ 'COMMUNICATIONS.none' | translate | capitalize }}</div>
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="numberofObjectsUpcoming">
		<mat-header-cell *matHeaderCellDef mat-sort-header>{{
			'BOOKINGS.number_of_objects_upcoming' | translate | capitalize
		}}</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			{{ tag.upcoming }}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="numberofObjectsHistorical">
		<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BOOKINGS.number_of_objects_history' | translate | capitalize }}</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			{{ tag.history }}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="dashboard">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Dashboard</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			<mat-checkbox [checked]="tag.dashboard" [disabled]="true"></mat-checkbox>
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="allowedFlightTags">
		<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TAGS.allowedFlightTags' | translate | capitalize }}</mat-header-cell>
		<mat-cell *matCellDef="let tag">
			@if (tag.type === 'booking' && tag.allowedFlightTags.length > 0) {

			<mat-chip-listbox disabled="true">
				@for (flightTag of tag.allowedFlightTags; track $index) {
				<mat-chip-option
					disabled="true"
					[value]="flightTag"
					[ngStyle]="{
						'background-color': flightTag.backgroundColor || 'initial',
						color: flightTag.fontColor || 'initial',
						cursor: 'not-allowed'
					}"
				>
					<div *ngIf="upcoming === undefined">{{ flightTag.tag }}</div>
					<div *ngIf="upcoming !== undefined">{{ flightTag.tag }} ({{ flightTag.upcoming }})</div>
				</mat-chip-option>
				}
			</mat-chip-listbox>
			} @if (tag.type === 'booking' && tag.allowedFlightTags.length === 0) {
			<div>{{ 'TAGS.all_flights_allowed' | translate | capitalize }}</div>
			} @if (tag.type === 'flight') {
			<span *ngIf="!tag.onlyAllowedFlightTags">{{ 'TAGS.not_restricted' | translate | capitalize }}</span>
			<span *ngIf="tag.onlyAllowedFlightTags && tag.bookingTags.length === 0">{{
				'TAGS.no_bookings_allowed' | translate | capitalize
			}}</span>
			<span *ngIf="tag.onlyAllowedFlightTags && tag.bookingTags.length > 0"
				>{{ 'TAGS.onlyAllowedFlightTags' | translate | capitalize }}: <strong>{{ listBookingTags(tag.bookingTags) }}</strong></span
			>
			}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="action">
		<mat-header-cell *matHeaderCellDef style="min-width: 300px">{{ 'APPLICATION.action' | translate | capitalize }}</mat-header-cell>
		<mat-cell *matCellDef="let tag" style="min-width: 300px">
			<button
				[disabled]="tag.locked"
				mat-flat-button
				[color]="tag.enabled ? 'primary' : 'warn'"
				(click)="disableOrEnableTag(tag)"
				[matTooltip]="(tag.enabled ? 'TAGS.disable_tag' : 'TAGS.enable_tag') | translate | capitalize"
				matTooltipPosition="left"
			>
				{{ (tag.enabled ? 'TAGS.enabled' : 'TAGS.disabled') | translate | capitalize }}
			</button>
			<button
				[disabled]="tag.locked || tag.upcoming > 0 || tag.history > 0"
				mat-icon-button
				color="warn"
				matTooltip="{{ 'TAGS.delete' | translate | capitalize }}"
				(click)="deleteTag(tag)"
			>
				<mat-icon>delete</mat-icon>
			</button>
			<button mat-icon-button matTooltip="{{ 'TAGS.edit' | translate | capitalize }}" (click)="editTag(tag)">
				<mat-icon>edit</mat-icon>
			</button>
		</mat-cell>
	</ng-container>
	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
