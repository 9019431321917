<div style="display: flex; flex-direction: column; gap: 20px; width: 345px">
	<mat-button-toggle-group
		*ngIf="screen === 'booking' || screen === 'flight'"
		[disabled]="!filterActive"
		name="filter"
		[(ngModel)]="filter"
		(change)="toggleFilter()"
	>
		<mat-button-toggle value="all">{{ 'DASHBOARDS.show_all_messages' | translate | capitalize }}</mat-button-toggle>
		<mat-button-toggle value="unread"
			><span [matBadge]="unreadSms" matBadgeColor="accent" [matBadgeHidden]="unreadSms === 0">
				{{ 'DASHBOARDS.show_unread' | translate | capitalize }}
			</span></mat-button-toggle
		>
		<mat-button-toggle *ngIf="screen === 'booking'" value="booking">{{
			'DASHBOARDS.show_messages_from_this_booking' | translate | capitalize
		}}</mat-button-toggle>
		<mat-button-toggle *ngIf="screen === 'flight'" value="flight">{{
			'DASHBOARDS.show_messags_from_this_flight' | translate | capitalize
		}}</mat-button-toggle>
	</mat-button-toggle-group>
	<!-- sms reminder -->
	<div>
		<button
			*ngIf="screen === 'flight' && filter === 'flight' && whatsapp?.mode !== 'ON'"
			mat-flat-button
			color="accent"
			(click)="sendReminder()"
			[disabled]="flight?.passengerCounters?.passengersCountConfirmed === 0"
		>
			<mat-icon>sms</mat-icon> {{ 'FLIGHTS.reminder_sms' | translate | capitalize }} ({{ flight?.bookings?.list?.length }})
		</button>
		<button
			*ngIf="screen === 'flight' && filter === 'flight' && whatsapp?.mode === 'ON'"
			mat-flat-button
			style="background-color: #25d366; color: white"
			(click)="sendReminderWA()"
			[disabled]="flight?.passengerCounters?.passengersCountConfirmed === 0"
		>
			<mat-icon>sms</mat-icon> {{ 'COMMUNICATIONS.reminder_wa' | translate | capitalize }} ({{ flight?.bookings?.list?.length }})
		</button>
	</div>
	<mat-card appearance="outlined">
		<mat-card-header>
			<mat-card-title *ngIf="type === 'BOO'">SMS {{ 'APPLICATION.bookings' | translate | capitalize }}</mat-card-title>
			<mat-card-title *ngIf="type === 'CRE'">SMS Crew</mat-card-title>
			<mat-card-subtitle>{{ 'DASHBOARDS.most_recent_sms_per_contact' | translate | capitalize }}</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content style="margin: 0px; padding: 0px">
			<app-sms-by-contact
				[conversations]="conversations"
				[screen]="screen"
				(listViewChanged)="changeFilterActive($event)"
			></app-sms-by-contact>
		</mat-card-content>
		<mat-card-actions>
			<button *ngIf="filter === 'all' && filterActive" mat-flat-button (click)="showMoreHistory()">More...</button>
		</mat-card-actions>
	</mat-card>
</div>
