import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { SmsService } from 'src/app/core/services/sms/sms.service';
import { SMS_CHARS } from 'src/app/core/utility/conversion';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { SmsConversationType, SmsDirection, SmsRepeat, SmsType, WhatsappSettings } from 'src/app/core/graphql/generated/gen-types';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { CrewService } from 'src/app/core/services/crew/crew.service';

@Component({
	selector: 'app-sms-by-contact',
	templateUrl: './sms-by-contact.component.html',
	styleUrls: ['./sms-by-contact.component.scss'],
})
export class SMSByContactComponent implements OnInit, OnChanges {
	@Input() conversations: any[];
	@Input() screen: string;
	@Output() listViewChanged = new EventEmitter<boolean>();

	selectedConversation: {
		smsType: SmsType;
		id: string;
		name: string;
		phone: string;
		smsUnread: number;
		smsLatest: { direction: SmsDirection };
	};
	selectedBooking: any;
	selectedCrew: any;
	messages: any[] = [];
	listView = true;
	replyText: String;
	smsUp = false;
	upEnabled = false;
	whatsapp: WhatsappSettings;
	whatsappConnected: boolean = false;

	constructor(
		private router: Router,
		private smsService: SmsService,
		private bookingService: BookingService,
		private crewService: CrewService,
		private tenantSettingService: TenantSettingService
	) {}

	ngOnInit() {
		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			if (result.data.tenantSetting.upEnabled) {
				this.upEnabled = true;
			}
			if (result.data.tenantSetting.whatsapp) {
				this.whatsapp = result.data.tenantSetting.whatsapp;
			}
		});
		if (this.screen === 'booking' || this.screen === 'crew') {
			this.selectedBooking = this.conversations[0];
		}

		this.smsService.getWaConnected()?.subscribe((connected) => {
			this.whatsappConnected = connected;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.conversations) {
			this.conversations = changes.conversations.currentValue ?? [];
			if (this.screen === 'booking' && this.conversations[0]) {
				this.selectedBooking = this.conversations[0];
				this.bookingService.getBooking(this.selectedBooking.id).refetch();
				// this.smsService.smsByBooking(this.selectedBooking.id).refetch();
			}
			if (this.screen === 'crew' && this.conversations[0]) {
				this.selectedCrew = this.conversations[0];
				this.crewService.get(this.selectedCrew.id).refetch();
				this.smsService.smsByCrew(this.selectedCrew.id).refetch();
			}
		}
		if (changes.screen) {
			this.screen = changes.screen.currentValue;
			if (this.screen === 'booking' || this.screen === 'crew') {
				this.selectedBooking = this.conversations[0];
			}
		}
	}

	totalUnread() {
		if (!this.conversations) return;
		return this.conversations.reduce((sum, x) => (sum += x?.smsUnread ?? 0), 0);
	}

	formatDate(date: any) {
		if (!date) return;
		const today = dayjs();
		const dateToFormat = dayjs(date);
		let friendlyDate = dateToFormat.format('dd D/MM/YY');
		if (dateToFormat.isSame(today, 'days')) {
			friendlyDate = dateToFormat.format('HH:mm');
		}
		return friendlyDate;
	}

	showSMSList(conversation: any) {
		// unssubscribe from previous conversation
		if (this.selectedConversation) {
			this.smsService.unsubscribeSmsByBooking(this.selectedConversation.id);
		}
		// mark first all messages as read
		this.markSMSesAsRead(conversation);
		this.selectedConversation = null;
		this.listView = true;
		this.listViewChanged.emit(this.listView);
	}

	markSMSesAsRead(conversation) {
		if (conversation.smsType === SmsConversationType.Boo) {
			this.smsService.markBookingMessagesRead([conversation.id]).subscribe();
		}
		if (conversation.smsType === SmsConversationType.Cre) {
			this.smsService.markCrewMessagesRead(conversation.id).subscribe();
		}
	}

	markSMSesAsReadAll(conversations: any) {
		const bookingIds = conversations.filter((c) => c.smsUnread).map((c) => c.id);
		this.smsService.markBookingMessagesRead(bookingIds).subscribe();
	}

	showSelectedSMS(conversation) {
		this.selectedConversation = {
			smsType: conversation.smsType,
			id: conversation.id,
			name: '',
			phone: '',
			smsUnread: conversation.smsUnread,
			smsLatest: conversation.smsLatest,
		};
		if (conversation.smsType === SmsConversationType.Boo) {
			this.selectedConversation.name = conversation.contactName;
			this.selectedConversation.phone = conversation.contactPhone;
			this.bookingService.getBooking(this.selectedConversation.id).valueChanges.subscribe((result) => {
				this.selectedBooking = result.data.booking;
			});
			this.smsService.smsByBooking(this.selectedConversation.id).subscribe((messages) => {
				this.messages = messages;
			});
		}
		if (conversation.smsType === SmsConversationType.Cre) {
			this.selectedConversation.name = conversation.name;
			this.selectedConversation.phone = conversation.phone;
			this.smsService.smsByCrew(this.selectedConversation.id).valueChanges.subscribe((result) => {
				this.messages = result.data.smsByCrew;
			});
		}
		this.listView = false;
		this.listViewChanged.emit(this.listView);
	}

	smsReply(conversation) {
		// mark first all smses as read
		this.markSMSesAsRead(conversation);
		if (conversation.smsType === SmsConversationType.Boo) {
			this.smsService
				.sendBookingSMS(conversation.id, {
					text: this.replyText,
					up: this.smsUp,
					repeat: SmsRepeat.Single,
				})
				.subscribe();
		}
		if (conversation.smsType === SmsConversationType.Cre) {
			this.smsService
				.sendCrewSms(conversation.id, {
					text: this.replyText,
					up: this.smsUp,
					repeat: SmsRepeat.Single,
				})
				.subscribe();
		}
	}

	messageReply(conversation) {
		// mark first all smses as read
		this.markSMSesAsRead(conversation);
		if (conversation.smsType === SmsConversationType.Boo) {
			this.smsService
				.sendBookingMessage(conversation.id, {
					text: this.replyText,
					up: this.smsUp,
					repeat: SmsRepeat.Single,
				})
				.subscribe();
		}
	}

	characterCount(text: string) {
		if (text) {
			return SMS_CHARS - (text.length - (this.smsCount(text) - 1) * SMS_CHARS);
		}
		return 0;
	}

	smsCount(text: string) {
		if (text) {
			return Math.floor((text.length - 1) / SMS_CHARS) + 1;
		}
		return 0;
	}

	viewDetailsBooking(conversation) {
		this.navigateToBooking(conversation.id);
	}

	viewDetailsFlight(conversation) {
		this.navigateToFlight(conversation.flight?.id);
	}

	navigateToBooking(id?) {
		let url = '/booking';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}

	navigateToFlight(id?) {
		let url = '/flight';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}
}
