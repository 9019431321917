<app-toolbar (toggleMenu)="toggleMenu()"></app-toolbar>
<mat-sidenav-container>
	<mat-sidenav style="display: flex; flex-direction: column" [mode]="mode" [opened]="sideNavOpened">
		<app-side-menu></app-side-menu>
	</mat-sidenav>
	<mat-sidenav-content>
		<div *ngIf="!xsmall && warningMessage().smsSubscriptionWarning" class="message">
			<div style="display: flex; justify-content: space-around; align-items: center; padding-top: 8px">
				<mat-icon class="mat-error-icon">warning</mat-icon>
				<div>
					{{ 'SUBSCRIPTION.sms_warning' | translate | capitalize }}
					<a routerLink="/subscription">{{ 'SUBSCRIPTION.order_sms' | translate | capitalize }}</a>
				</div>
				<mat-icon class="mat-error-icon">warning</mat-icon>
			</div>
		</div>
		<div *ngIf="!xsmall && warningMessage().releaseNotesWarning" class="message">
			<div style="display: flex; justify-content: space-around; align-items: center">
				<div style="display: flex; justify-content: center; align-items: center; gap: 10px">
					<p>{{ ('VALIDATIONS.new_features_available' | translate | capitalize).replace('{0}', releaseNotesCount) }}</p>
					<button mat-icon-button (click)="showReleaseNotes()"><mat-icon>open_in_browser</mat-icon></button>
				</div>
			</div>
		</div>
		<mat-drawer-container [ngStyle]="{ height: 'calc(100vh - ' + warningMessage().margin + ')' }">
			<mat-drawer
				*ngIf="isOperator"
				#drawer
				[mode]="drawerMode"
				position="end"
				[ngStyle]="{
					'min-width': '380px',
					'padding.px': 10,
					border: '1px solid black'
				}"
			>
				<button *ngIf="drawerMode === 'over'" mat-icon-button (click)="toggleDrawerMode()" style="margin-bottom: 10px">
					<mat-icon>
						<span *ngIf="drawerMode === 'over'" class="material-symbols-outlined"> keep </span>
					</mat-icon>
				</button>
				<button
					*ngIf="drawerMode === 'side'"
					mat-icon-button
					(click)="toggleDrawerMode(); toggleDrawerStatus()"
					style="margin-bottom: 10px"
				>
					<mat-icon>
						<span class="material-symbols-outlined"> keep_off </span>
					</mat-icon>
				</button>
				<app-dashboard-sms *ngIf="isOperator" type="BOO"></app-dashboard-sms>
			</mat-drawer>
			<mat-drawer-content>
				<div
					*ngIf="!isDrawerVisible() && isOperator && !xsmall"
					class="overlay"
					[@slideInOut]="drawerState"
					[ngStyle]="{ top: overlayTop + 'px' }"
					(mousedown)="startDrag($event)"
				>
					<button mat-button style="color: #f0f8ff" (click)="toggleDrawerStatus()">
						<mat-icon aria-hidden="false" [matBadge]="unreadSms" matBadgeColor="accent" [matBadgeHidden]="unreadSms === 0">sms</mat-icon>SMS
					</button>
				</div>
				<router-outlet></router-outlet>
			</mat-drawer-content>
		</mat-drawer-container>
	</mat-sidenav-content>
</mat-sidenav-container>
